import 'bootstrap';
import '../styles/style.scss';
import './utils/detectTouch';
import './utils/responsiveHelper';
import ResizeHandler from './utils/resize-active';
import initSwiper from './components/swiper';
import initCustomSelect from './components/customSelect';
import initBurgerMenu from './components/burgerMenu';
import initAccessibilityNav from './components/accessibilityNav';
import initCustomMap from './components/customMap';
import initMobileNav from './components/mobileNav';
import initFixedHeader from './components/fixedHeader';
import initVideo from './components/video';
import initAjaxFiltering from './components/ajaxFiltering';
import initAos from './components/aos';

const resizeHandler = new ResizeHandler();

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
	resizeHandler.init();
	initSwiper();
	initBurgerMenu();
	initAccessibilityNav();
	initCustomSelect();
	initCustomMap();
	initMobileNav();
	initVideo();
	initAjaxFiltering();
	initFixedHeader();
	initAos();
});
