export default function initLocationsFiltering() {
  const hiddenClass = 'hidden-item';

  document.querySelectorAll('.locations').forEach((holder) => {
    const filterSelect = holder.querySelector('.filter-select');
    const items = holder.querySelectorAll('[data-location]');
    let currentFilter = 'all';

    if (!filterSelect || !items) return;

    filterSelect.addEventListener('change', (event) => {
      currentFilter = event.target.value.toLowerCase().trim();

      filterItems();
    });

    function filterItems() {
      if (currentFilter === 'all') {
        items.forEach((item) => {
          item.classList.remove(hiddenClass);
        });
      } else {
        items.forEach((item) => {
          const location = item.getAttribute('data-location').toLowerCase().trim();

          if (location === currentFilter) {
            item.classList.remove(hiddenClass);
          } else {
            item.classList.add(hiddenClass);
          }
        });
      }
    }
  });
}
