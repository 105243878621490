import AjaxFiltering from '../plugins/ajaxFilteringPlugin';

export default function initAjaxFiltering() {
  document.querySelectorAll('.filter-section').forEach(function(elem) {
    new AjaxFiltering({
      holder: elem,
      container: '.filter-container',
      items: '.filter-item',
      filterSelects: '.filter-panel select',
      activeClass: 'active',
      loadClass: 'loading',
      btnReset: '.filter-form__btn-reset',
      usePaging: true,
      paging: '.pagination',
      infiniteScroll: false,
      onSuccess: function() {
        window.dispatchEvent(new CustomEvent('refreshAOS'));
      }
    });
  });
}
