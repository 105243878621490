export default class AccessibilityNav {
  constructor(domNode, breakpoint) {
    const msgPrefix = 'Menubar constructor argument menubarNode ';

    // Check whether menubarNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }

    // Check whether menubarNode has descendant elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }

    // Check whether menubarNode has A elements
    let e = domNode.firstElementChild;

    while (e) {
      const menubarItem = e.firstElementChild;
      if (e && menubarItem && menubarItem.tagName !== 'A') {
        throw new Error(msgPrefix + 'has child elements are not A elements.');
      }
      e = e.nextElementSibling;
    }

    this.isMenubar = true;

    this.domNode = domNode;

    this.breakpoint = breakpoint || '768px';

    this.menubarItems = []; // See Menubar init method
    this.firstChars = []; // See Menubar init method

    this.firstItem = null; // See Menubar init method
    this.lastItem = null; // See Menubar init method

    this.hasFocus = false; // See MenubarItem handleFocus, handleBlur
    this.hasHover = false; // See Menubar handleMouseover, handleMouseout

    this.init();
  }

  init() {
    let menubarItem, childElement, menuElement, textContent, numItems;

    // Traverse the element children of menubarNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    let elem = this.domNode.firstElementChild;

    while (elem) {
      menuElement = elem.firstElementChild;

      if (elem && menuElement && menuElement.tagName === 'A') {
        menubarItem = new MenubarItem(menuElement, this);
        menubarItem.init();
        this.menubarItems.push(menubarItem);
        textContent = menuElement.textContent.trim();
        this.firstChars.push(textContent.substring(0, 1).toLowerCase());
      }

      elem = elem.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menubarItems.length;

    if (numItems > 0) {
      this.firstItem = this.menubarItems[0];
      this.lastItem = this.menubarItems[numItems - 1];
    }
  }

  setFocusToItem(newItem) {
    let flag = false;

    for (let i = 0; i < this.menubarItems.length; i++) {
      const mbi = this.menubarItems[i];

      if (mbi.domNode.tabIndex === 0) {
        flag = mbi.domNode.getAttribute('aria-expanded') === 'true';
      }

      if (mbi.popupMenu) {
        mbi.popupMenu.close();
      }
    }

    newItem.domNode.focus();

    if (flag && newItem.popupMenu) {
      newItem.popupMenu.open();
    }
  }

  setFocusToFirstItem() {
    this.setFocusToItem(this.firstItem);
  }

  setFocusToLastItem() {
    this.setFocusToItem(this.lastItem);
  }

  setFocusToPreviousItem(currentItem) {
    let index, newItem;

    if (currentItem === this.firstItem) {
      newItem = this.lastItem;
    } else {
      index = this.menubarItems.indexOf(currentItem);
      newItem = this.menubarItems[index - 1];
    }

    this.setFocusToItem(newItem);
  }

  setFocusToNextItem(currentItem) {
    let index, newItem;

    if (currentItem === this.lastItem) {
      newItem = this.firstItem;
    } else {
      index = this.menubarItems.indexOf(currentItem);
      newItem = this.menubarItems[index + 1];
    }

    this.setFocusToItem(newItem);
  }

  setFocusByFirstCharacter(currentItem, charStr) {
    let start; let index; const char = charStr.toLowerCase();
    const flag = currentItem.domNode.getAttribute('aria-expanded') === 'true';

    // Get start index for search based on position of currentItem
    start = this.menubarItems.indexOf(currentItem) + 1;
    if (start === this.menubarItems.length) {
      start = 0;
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, char);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, char);
    }

    // If match was found...
    if (index > -1) {
      this.setFocusToItem(this.menubarItems[index]);
    }
  }

  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[i]) {
        return i;
      }
    }
    return -1;
  }
}

// Menubar Item Module
class MenubarItem {
  constructor(domNode, menuObj) {
    this.menu = menuObj;
    this.domNode = domNode;
    this.popupMenu = false;

    this.hasFocus = false;
    this.hasHover = false;

    this.isMenubarItem = true;
  }

  init() {
    this.keyDownHandler = this.handleKeydown.bind(this);
    this.focusHandler = this.handleFocus.bind(this);
    this.blurHandler = this.handleBlur.bind(this);
    this.mouseoverHandler = this.handleMouseover.bind(this);
    this.mouseoutHandler = this.handleMouseout.bind(this);
    this.touchHandler = this.handleTouch.bind(this);
    const nextElement = this.getDropdown(this.domNode);

    if (nextElement) {
      this.popupMenu = new PopupMenu(nextElement, this);
      this.popupMenu.init();
    }

    const mediaQuery = window.matchMedia(`(min-width: ${this.menu.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange.bind(this));

    // Initial check
    handleChange.bind(this)(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        // attach events for aa items
        this.domNode.addEventListener('keydown', this.keyDownHandler);
        this.domNode.addEventListener('focus', this.focusHandler);
        this.domNode.addEventListener('blur', this.blurHandler);

        // set tabindex value
        // this.domNode.tabIndex = -1;

        if (nextElement) {
          // attach events for openers
          this.domNode.addEventListener('mouseover', this.mouseoverHandler);
          this.domNode.addEventListener('mouseout', this.mouseoutHandler);
          this.domNode.addEventListener('touchend', this.touchHandler);

          // set aria attributes
          this.domNode.setAttribute('aria-haspopup', 'true');
          this.domNode.setAttribute('aria-expanded', 'false');
        }
      } else {
        // remove events listeners from all items
        this.domNode.removeEventListener('keydown', this.keyDownHandler);
        this.domNode.removeEventListener('focus', this.focusHandler);
        this.domNode.removeEventListener('blur', this.blurHandler);

        // clear tabindex value
        this.domNode.tabIndex = '';

        if (nextElement) {
          // remove events listeners from openers
          this.domNode.removeEventListener('mouseover', this.mouseoverHandler);
          this.domNode.removeEventListener('mouseout', this.mouseoutHandler);
          this.domNode.removeEventListener('touchend', this.touchHandler);

          // remove aria attributes
          this.domNode.removeAttribute('aria-haspopup');
          this.domNode.removeAttribute('aria-expanded');
        }
      }
    }
  }

  getDropdown(el) {
    let nextElement = el.nextElementSibling;

    while (nextElement) {
      if (nextElement.tagName === 'UL') return nextElement;
      if (nextElement.tagName === 'DIV') return nextElement;
      nextElement = nextElement.nextElementSibling;
    }
  }

  handleKeydown(event) {
    const char = event.key;
    let flag = false;
    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/);
    }

    switch (event.key) {
      // case " ":
      // case "Enter":
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToFirstItem();
          flag = true;
        }
        break;

      case 'Left': // IE/Edge specific value
      case 'ArrowLeft':
        this.menu.setFocusToPreviousItem(this);
        flag = true;
        break;

      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        this.menu.setFocusToNextItem(this);
        flag = true;
        break;

      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToLastItem();
          flag = true;
        }
        break;

      case 'Home':
      case 'PageUp':
        this.menu.setFocusToFirstItem();
        flag = true;
        break;

      case 'End':
      case 'PageDown':
        this.menu.setFocusToLastItem();
        flag = true;
        break;

        // case "Tab":
        //   if (this.popupMenu) this.popupMenu.close(true);
        //   break;

      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this.popupMenu.close(true);
        break;

      default:
        if (isPrintableCharacter(char)) {
          this.menu.setFocusByFirstCharacter(this, char);
          flag = true;
        }
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setExpanded(value) {
    if (value) {
      this.domNode.setAttribute('aria-expanded', 'true');
    } else {
      this.domNode.setAttribute('aria-expanded', 'false');
    }
  }

  handleFocus() {
    this.menu.hasFocus = true;
  }

  handleBlur() {
    this.menu.hasFocus = false;
  }

  handleMouseover() {
    this.hasHover = true;
    this.popupMenu.open();
  }

  handleMouseout() {
    this.hasHover = false;
    setTimeout(this.popupMenu.close.bind(this.popupMenu), 10);
  }

  handleTouch(e) {
    if (this.domNode.getAttribute('aria-expanded') === 'false') {
      e.preventDefault();
    }

    if (this.popupMenu) {
      this.popupMenu.open();
      this.popupMenu.setFocusToFirstItem();
    }
  }
}

// Menu Item Module
class MenuItem {
  constructor(domNode, menuObj, breakpoint) {
    this.domNode = domNode;
    this.menu = menuObj;
    this.popupMenu = false;
    this.isMenubarItem = false;
    this.breakpoint = breakpoint;
  }

  init() {
    this.keyDownHandler = this.handleKeydown.bind(this);
    this.focusHandler = this.handleFocus.bind(this);
    this.blurHandler = this.handleBlur.bind(this);
    this.mouseoverHandler = this.handleMouseover.bind(this);
    this.mouseoutHandler = this.handleMouseout.bind(this);
    this.touchHandler = this.handleTouch.bind(this);

    // Initialize flyout menu
    const nextElement = this.getDropdown(this.domNode);

    if (nextElement) {
      this.popupMenu = new PopupMenu(nextElement, this);
      this.popupMenu.init();
    }

    const mediaQuery = window.matchMedia(`(min-width: ${this.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange.bind(this));

    // Initial check
    handleChange.bind(this)(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        this.domNode.addEventListener('keydown', this.keyDownHandler);
        this.domNode.addEventListener('focus', this.focusHandler);
        this.domNode.addEventListener('blur', this.blurHandler);
        this.domNode.addEventListener('mouseover', this.mouseoverHandler);
        this.domNode.addEventListener('mouseout', this.mouseoutHandler);
        this.domNode.addEventListener('touchend', this.touchHandler);

        // set tabindex value
        // this.domNode.tabIndex = -1;

        if (nextElement) {
          this.domNode.setAttribute('aria-haspopup', 'true');
          this.domNode.setAttribute('aria-expanded', 'false');
        }
      } else {
        // remove events listeners
        this.domNode.removeEventListener('keydown', this.keyDownHandler);
        this.domNode.removeEventListener('focus', this.focusHandler);
        this.domNode.removeEventListener('blur', this.blurHandler);
        this.domNode.removeEventListener('mouseover', this.mouseoverHandler);
        this.domNode.removeEventListener('mouseout', this.mouseoutHandler);
        this.domNode.removeEventListener('touchend', this.touchHandler);

        // clear tabindex value
        this.domNode.tabIndex = '';

        if (nextElement) {
          // remove aria attributes
          this.domNode.removeAttribute('aria-haspopup');
          this.domNode.removeAttribute('aria-expanded');
        }
      }
    }
  }

  getDropdown(el) {
    let nextElement = el.nextElementSibling;

    while (nextElement) {
      if (nextElement.tagName === 'UL') return nextElement;
      if (nextElement.tagName === 'DIV') return nextElement;
      nextElement = nextElement.nextElementSibling;
    }
  }

  /* EVENT HANDLERS */
  handleKeydown(event) {
    const char = event.key;
    let flag = false;

    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/);
    }

    switch (event.key) {
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        this.menu.setFocusToPreviousItem(this);
        flag = true;
        break;

      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        this.menu.setFocusToNextItem(this);
        flag = true;
        break;

      case 'Left': // IE/Edge specific value
      case 'ArrowLeft':
        this.menu.setFocusToController('previous', true);
        this.menu.close(true);
        flag = true;
        break;

      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToFirstItem();
        } else {
          this.menu.setFocusToController('next', true);
          this.menu.close(true);
        }
        flag = true;
        break;

      case 'Home':
      case 'PageUp':
        this.menu.setFocusToFirstItem();
        flag = true;
        break;

      case 'End':
      case 'PageDown':
        this.menu.setFocusToLastItem();
        flag = true;
        break;

      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this.menu.setFocusToController();
        this.menu.close(true);
        flag = true;
        break;

        // case "Tab":
        //   this.menu.setFocusToController();
        //   break;

      default:
        if (isPrintableCharacter(char)) {
          this.menu.setFocusByFirstCharacter(this, char);
          flag = true;
        }
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setExpanded(value) {
    if (value) {
      this.domNode.setAttribute('aria-expanded', 'true');
    } else {
      this.domNode.setAttribute('aria-expanded', 'false');
    }
  }

  handleFocus() {
    this.menu.hasFocus = true;
  }

  handleBlur() {
    this.menu.hasFocus = false;
    setTimeout(this.menu.close.bind(this.menu), 10);
  }

  handleMouseover() {
    this.menu.hasHover = true;
    this.menu.open();

    if (this.popupMenu) {
      this.popupMenu.hasHover = true;
      this.popupMenu.open();
    }
  }

  handleMouseout() {
    if (this.popupMenu) {
      this.popupMenu.hasHover = false;
      this.popupMenu.close(true);
    } else {
      this.menu.hasHover = false;
      setTimeout(this.menu.close.bind(this.menu), 10);
    }
  }

  // touch
  handleTouch(e) {
    if (this.domNode.getAttribute('aria-expanded') === 'false') {
      e.preventDefault();
    }

    if (this.popupMenu) {
      this.popupMenu.open();
      this.popupMenu.setFocusToFirstItem();
    }
  }
}

// Popup Menu Module
class PopupMenu {
  constructor(domNode, controllerObj) {
    const msgPrefix = 'PopupMenu constructor argument domNode ';

    // Check whether domNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }
    // Check whether domNode has child elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }
    // Check whether domNode descendant elements have A elements
    let childElement = domNode.firstElementChild;
    while (childElement) {
      const menuitem = childElement.firstElementChild;
      if (menuitem && menuitem === 'A') {
        throw new Error(msgPrefix + 'has descendant elements that are not A elements.');
      }
      childElement = childElement.nextElementSibling;
    }

    this.isMenubar = false;

    this.domNode = domNode;
    this.controller = controllerObj;

    this.menuitems = []; // See PopupMenu init method
    this.firstChars = []; // See PopupMenu init method

    this.firstItem = null; // See PopupMenu init method
    this.lastItem = null; // See PopupMenu init method

    this.hasFocus = false; // See MenuItem handleFocus, handleBlur
    this.hasHover = false; // See PopupMenu handleMouseover, handleMouseout
  }

  init() {
    let childElement, menuElement, menuItem, textContent, numItems;
    const mouseoverHandler = this.handleMouseover.bind(this);
    const mouseoutHandler = this.handleMouseout.bind(this);
    const outsideClickHandler = this.handleOutsideClick.bind(this);
    const domNode = this.domNode;

    const mediaQuery = window.matchMedia(`(min-width: ${this.controller.menu.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange);

    // Initial check
    handleChange(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        domNode.addEventListener('mouseover', mouseoverHandler);
        domNode.addEventListener('mouseout', mouseoutHandler);
        document.addEventListener('click', outsideClickHandler);
      } else {
        domNode.removeEventListener('mouseover', mouseoverHandler);
        domNode.removeEventListener('mouseout', mouseoutHandler);
        document.removeEventListener('click', outsideClickHandler);
      }
    }

    // Traverse the element children of domNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    childElement = this.domNode.tagName === 'DIV' ? this.domNode.querySelector(':scope > ul').firstElementChild : this.domNode.firstElementChild;

    while (childElement) {
      menuElement = childElement.firstElementChild;

      if (menuElement && menuElement.tagName === 'A') {
        const breakpoint = this.controller.breakpoint || this.controller.menu.breakpoint || this.controller.menu.controller.menu.breakpoint;

        menuItem = new MenuItem(menuElement, this, breakpoint);
        menuItem.init();
        this.menuitems.push(menuItem);
        textContent = menuElement.textContent.trim();
        this.firstChars.push(textContent.substring(0, 1).toLowerCase());
      }

      childElement = childElement.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menuitems.length;
    if (numItems > 0) {
      this.firstItem = this.menuitems[0];
      this.lastItem = this.menuitems[numItems - 1];
    }
  }

  eventPath(evt) {
    const path = (evt.composedPath && evt.composedPath()) || evt.path;
    const target = evt.target;

    if (path != null) {
      // Safari doesn't include Window, but it should.
      return (path.indexOf(window) < 0) ? path.concat(window) : path;
    }

    if (target === window) {
      return [window];
    }

    function getParents(node, memo) {
      memo = memo || [];
      const parentNode = node.parentNode;

      if (!parentNode) {
        return memo;
      } else {
        return getParents(parentNode, memo.concat(parentNode));
      }
    }

    return [target].concat(getParents(target), window);
  }

  handleOutsideClick(event) {
    const path = this.eventPath(event);
    let flag = false;

    path.forEach((el) => {
      const isEqual = el === this.holder;

      if (isEqual) {
        flag = isEqual;

        return false;
      }
    });

    if (!flag) {
      this.hasHover = false;
      setTimeout(this.close.bind(this), 1);

      this.controller.menu.close && this.controller.menu.close(true);
      this.controller.hasFocus = false;
    }
  }

  handleMouseover() {
    this.hasHover = true;
  }

  handleMouseout() {
    this.hasHover = false;
    setTimeout(this.close.bind(this), 10);
  }

  setFocusToController(command, flag) {
    if (typeof command !== 'string') {
      command = '';
    }

    function setFocusToMenubarItem(controller, close) {
      while (controller) {
        if (controller.isMenubarItem) {
          controller.domNode.focus();
          return controller;
        } else {
          if (close) {
            controller.menu.close(true);
          }
          controller.hasFocus = false;
        }
        controller = controller.menu.controller;
      }
      return false;
    }

    if (command === '') {
      if (this.controller && this.controller.domNode) {
        this.controller.domNode.focus();
      }
      return;
    }

    if (!this.controller.isMenubarItem) {
      this.controller.domNode.focus();
      this.close();

      if (command === 'next') {
        const menubarItem = setFocusToMenubarItem(this.controller, false);
        if (menubarItem) {
          menubarItem.menu.setFocusToNextItem(menubarItem, flag);
        }
      }
    } else {
      if (command === 'previous') {
        this.controller.menu.setFocusToPreviousItem(this.controller, flag);
      } else if (command === 'next') {
        this.controller.menu.setFocusToNextItem(this.controller, flag);
      }
    }
  }

  setFocusToFirstItem() {
    this.firstItem.domNode.focus();
  }

  setFocusToLastItem() {
    this.lastItem.domNode.focus();
  }

  setFocusToPreviousItem(currentItem) {
    let index;

    if (currentItem === this.firstItem) {
      this.lastItem.domNode.focus();
    } else {
      index = this.menuitems.indexOf(currentItem);
      this.menuitems[index - 1].domNode.focus();
    }
  }

  setFocusToNextItem(currentItem) {
    let index;

    if (currentItem === this.lastItem) {
      this.firstItem.domNode.focus();
    } else {
      index = this.menuitems.indexOf(currentItem);
      this.menuitems[index + 1].domNode.focus();
    }
  }

  setFocusByFirstCharacter(currentItem, charStr) {
    let start; let index; const char = charStr.toLowerCase();

    // Get start index for search based on position of currentItem
    start = this.menuitems.indexOf(currentItem) + 1;
    if (start === this.menuitems.length) {
      start = 0;
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, char);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, char);
    }

    // If match was found...
    if (index > -1) {
      this.menuitems[index].domNode.focus();
    }
  }

  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[i]) {
        return i;
      }
    }
    return -1;
  }

  /* MENU DISPLAY METHODS */

  open() {
    this.domNode.classList.add('accessibility-drop-hover');
    this.domNode.closest('li').classList.add('accessibility-hover');

    this.controller.setExpanded(true);
  }

  close(force) {
    let controllerHasHover = this.controller.hasHover;
    let hasFocus = this.hasFocus;

    for (let i = 0; i < this.menuitems.length; i++) {
      const mi = this.menuitems[i];
      if (mi.popupMenu) {
        hasFocus = hasFocus || mi.popupMenu.hasFocus;
      }
    }

    if (!this.controller.isMenubarItem) {
      controllerHasHover = false;
    }

    if (force || (!hasFocus && !this.hasHover && !controllerHasHover)) {
      const parentItem = this.domNode.closest('li');

      this.domNode.classList.remove('accessibility-drop-hover');
      parentItem.classList.remove('accessibility-hover');
      this.controller.setExpanded(false);

      const stillActive = this.domNode.querySelectorAll('.accessibility-hover');

      if (stillActive.length) {
        stillActive.forEach(item => {
          item.classList.remove('accessibility-hover');
          item.querySelector('.accessibility-drop-hover').classList.remove('accessibility-drop-hover');
          item.querySelector('[aria-expanded="true"]').setAttribute('aria-expanded', 'false');
        });
      }
    }
  }
}
