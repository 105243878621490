import Choices from 'choices.js';

export default function initCustomSelect() {
  document.querySelectorAll('.js-choice').forEach((elem) => {
    const choiceInstance = new Choices(elem, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
      silent: true,
      allowHTML: true,
    });

    elem.choices = choiceInstance;

    elem.closest('.choices').addEventListener('focusout', (e) => {
      const element = e.currentTarget;

      element.classList.add('choices--focus');

      if (!e.currentTarget.contains(e.relatedTarget)) {
        setTimeout(() => {
          choiceInstance.hideDropdown();

          element.classList.remove('choices--focus');
        });
      }
    });
  });
}
