import BurgerMenu from '../plugins/burgerMenuPlugin';

export default function initBurgerMenu() {
  new BurgerMenu(document.body, {
    openerSelector: '.header__toggle',
    activeClass: 'nav-active',
    dropSelector: '.header__nav',
    breakpoint: '768px'
  });
}
