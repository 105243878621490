/* eslint-disable */

/*
 * Ajax filtering module
 */
export default class AjaxFiltering {
	constructor(options) {
		this.options = Object.assign({
			holder: null,
			container: '.js-ajax-container',
			items: '.filter-item',
			filterForm: '.filter-form',
			filterSelects: 'select',
			loadingClass: 'loading',
			filteringClass: 'filtering',
			hiddenClass: 'hidden',
			animateClass: 'animate',
			btnReset: '.btn-reset',
			paging: '.pagination',
			usePaging: false,
			infiniteScroll: false,
			animSpeed: 500,
			delay: 500
		}, options);

		if (this.options.holder) {
			this.holder = this.options.holder;
			this.init();
		}
	}

	init() {
		if (this.options.holder) {
			this.findElements();
			this.attachEvents();
			this.makeCallback('onInit', this);
		}
	}

	findElements() {
		this.header = document.querySelector('.header');
		this.container = this.holder.querySelector(this.options.container);
		this.filterForm = this.holder.querySelector(this.options.filterForm);
		this.filterSelects = this.holder.querySelectorAll(this.options.filterSelects);
		this.btnReset = this.holder.querySelector(this.options.btnReset);
		this.paging = this.holder.querySelector(this.options.paging);
		this.ajaxBusy = false;
		this.activeFilters = '';
	}

	attachEvents() {
		this.sendRequest = () => {
			if (!this.ajaxBusy) {
				this.ajaxRequest(null);
			}
		};

		if (this.filterForm) {
			this.filterForm.addEventListener('submit', (e) => {
				e.preventDefault();
				this.sendRequest();
			});
		}

		if (this.paging) {
			this.paging.addEventListener('click', (e) => {
				if (e.target.tagName === 'A') {
					e.preventDefault();

					const link = e.target;

					if (!this.ajaxBusy) {
						this.ajaxRequest(null, link.dataset.page || '1');
					}
				}
			});
		}

		if (this.filterSelects.length) {
			this.filterSelects.forEach((select) => {
				select.addEventListener('change', this.sendRequest);
			});
		}

		if (this.btnReset) {
			this.btnReset.addEventListener('click', () => {
				if (this.filterForm) {
					this.filterForm.reset();
				}

				this.filterSelects.forEach((select) => {
					const options = select.querySelectorAll('option');

					if (options.length) {
						options.forEach((option) => {
							option.selected = false
						});

						options[0].selected = true;
					}

					if (select.choices) {
						select.choices.refresh(false, true, true);
					}
				});

				this.sendRequest();
			});
		}
	}

	ajaxRequest(src, pageNum = null) {
		const filterFormSerialize = this.filterForm ? this.getFormData(this.filterForm) : '';
		let page = '';

		if (this.options.usePaging) {
			page = pageNum ? '&page_num=' + pageNum : '&page_num=1';
		}

		this.ajaxBusy = true;

		if (this.btnMore) {
			this.btnMore.classList.add(this.options.hiddenClass);
		}

		this.holder.classList.add(this.options.loadingClass);

		if (!src) {
			this.holder.classList.add(this.options.filteringClass);
		}

		let url = src ? src : this.holder.dataset.action;
		let params = filterFormSerialize + page;
		const method = this.holder.dataset.method || 'POST';
		const isScrollToTop = pageNum ? true : false;
		const isFilterItems = src ? false : true;

		const options = {
			method,
			headers: {
				'Content-Type': 'text/html'
			}
		};

		params = params.slice(1);
		url = url.indexOf('?') === -1 ? url + `?${params}` : url + `&${params}`;

		fetch(url, options)
			.then((response) => response.text())
			.then((data) => {
				this.onSuccess(data, isFilterItems, isScrollToTop);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}

	onSuccess(data, isFilterItems, isScrollToTop) {
		const loadedBlock = document.createElement('div');

		loadedBlock.innerHTML = data;

		const items = loadedBlock.querySelectorAll(this.options.items);
		const paging = loadedBlock.querySelector(this.options.paging);

		setTimeout(() => {
			if (isFilterItems) {
				this.container.innerHTML = '';
				this.makeCallback('onFilter', loadedBlock);
			}

			this.holder.classList.remove(this.options.filteringClass);

			items.forEach((item) => {
				item.classList.add(this.options.hiddenClass);
				this.container.append(item);
			});

			if (this.paging) {
				if (paging) {
					this.paging.innerHTML = paging.innerHTML;
				} else {
					this.paging.innerHTML = '';
				}
			}

			this.makeCallback('onSuccess', items);
			this.holder.classList.remove(this.options.loadingClass);

			items.forEach((item) => {
				item.classList.add(this.options.animateClass);
				item.classList.remove(this.options.hiddenClass);
			});

			if (isScrollToTop) {
				const offset = this.holder.offsetTop - (this.header ? this.header.offsetHeight : 0);

				window.scrollTo({
					top: offset,
					behavior: 'smooth'
				});
			}

			this.ajaxBusy = false;
		}, this.options.delay);
	}

	getFormData(form) {
		const formData = new FormData(form);
		let formDataStr = '';

		for (const [key, value] of formData) {
			formDataStr += `&${key}=${value}`;
		}

		return formDataStr;
	}

	makeCallback(callbackName, ...args) {
		if (typeof this.options[callbackName] === 'function') {
			this.options[callbackName].apply(this, args);
		}
	}
}