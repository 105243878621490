import MobileNavigation from '../plugins/mobileNavPlugin';
import '../utils/responsiveHelper';

export default function initMobileNavigation() {
  let mobileNav = null;

  ResponsiveHelper.addRange({
    '..767': {
      on: function() {
        mobileNav = new MobileNavigation(document.querySelector('.header__nav-holder'), {
          listItems: 'li',
          opener: 'a',
          dropdown: '.drop-holder',
          btnBackClass: 'btn-back',
          btnBackPrefix: '',
          activeClass: 'active-item',
          activeListClass: 'active-nav',
          hasDropClass: 'has-drop',
          animSpeed: 500
        });
      }
    },
    '768..': {
      on: function() {
        if (mobileNav) {
          mobileNav.destroy();
        }
      }
    }
  });
}
