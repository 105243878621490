import * as d3 from 'd3';
import Handlebars from 'handlebars';
import * as focusTrap from 'focus-trap';

/*
* Custom Map module
*/
export default class CustomMap {
  constructor(elem, options) {
    this.options = {
      mapContainer: '.map-container',
      locationsHolder: '.locations-holder',
      mapDataAttr: 'data-json',
      markersDataAttr: 'data-markers',
      popupTemplateAttr: 'data-popup-template',
      locationTemplateAttr: 'data-location-template',
      popupClass: 'map-popup',
      activePopupClass: 'active-popup',
      filterSelect: '.filter-select',
      rightSideClass: 'right-side',
      leftSideClass: 'left-side',
      markerHolderClass: 'marker-holder',
      markerClass: 'marker',
      markerWidth: 10,
      markerHeight: 10,
      markerColor: '#A9FF73',
      markerHoverColor: '#EC811E',
      markerStrokeWidth: 1,
      markerStrokeColor: '#225182',
      statesColor: '#1C75BC',
      statesStrokeColor: '#fff',
      statesStrokeWidth: 1,
      hideDelay: 250,
      ...options
    };

    this.holder = elem;
    this.holder.CustomMap = this;

    if (!d3) return;

    this.init();
  }

  init() {
    this.mapContainer = this.holder.querySelector(this.options.mapContainer);
    this.locationsHolder = this.holder.querySelector(this.options.locationsHolder);
    this.filterSelect = this.holder.querySelector(this.options.filterSelect);

    if (!this.mapContainer) return;

    this.attachEvents();
  }

  attachEvents() {
    this.projection = d3.geoAlbersUsa().scale(1350).translate([487.5, 305]);
    this.path = d3.geoPath().projection(this.projection);
    this.focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    this.title = this.mapContainer.getAttribute('data-title');
    this.description = this.mapContainer.getAttribute('data-description');
    this.mapDataURL = this.mapContainer.getAttribute(this.options.mapDataAttr);
    this.markersDataURL = this.mapContainer.getAttribute(this.options.markersDataAttr);
    this.mapData = {};
    this.data = {};
    this.activeMarker = null;
    this.activePopup = null;
    this.animTimer = null;
    this.isFocused = false;

    if (!this.mapDataURL || !this.markersDataURL) return;

    this.loadData();
  }

  loadData() {
    const loadMapData = async() => {
      try {
        const resultData = await Promise.all([
          fetch(this.mapDataURL),
          fetch(this.markersDataURL)
        ]);
        return resultData;
      } catch (error) {
        console.error('Error fetching map or marker data:', error);
        throw error;
      }
    };

    loadMapData()
      .then((resultData) => {
        const onLoad = async() => {
          try {
            await resultData[0].json().then((res) => {
              this.mapData = res;
            }).catch((error) => {
              console.error('Error parsing map data:', error);
              throw error;
            });

            await resultData[1].json().then((res) => {
              this.data = res;
            }).catch((error) => {
              console.error('Error parsing marker data:', error);
              throw error;
            });
          } catch (error) {
            console.error('Error in onLoad:', error);
            throw error;
          }
        };

        onLoad()
          .then(() => {
            this.createMap();
          })
          .catch((error) => {
            console.error('Error creating map:', error);
          });
      })
      .catch((error) => {
        console.error('Error loading map data:', error);
      });
  }

  createMap() {
    if (Object.keys(this.mapData).length > 0 || Object.keys(this.data).length > 0) {
      this.createSVG();
      this.createStates();
      this.createMarkers();
      this.createPopups();
      this.createLocations();
      this.createSelectOptions();

      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && this.activePopup) {
          this.hidePopup();
        }
      });
    }

    this.makeCallback('onInit', this);
  }

  createSVG() {
    const svgTitleID = this.mapContainer.getAttribute('svg-title-id');
    const svgDescID = this.mapContainer.getAttribute('svg-description-id');

    this.svg = d3
      .select(this.mapContainer)
      .append('svg')
      .attr('viewBox', [0, 0, 975, 610]);

    if (this.title && svgTitleID) {
      this.svg.append('title')
        .attr('id', svgTitleID)
        .text(this.title);
    }

    if (this.description && svgDescID) {
      this.svg.append('desc')
        .attr('id', svgDescID)
        .text(this.description);
    }

    if (svgTitleID && svgDescID) {
      this.svg.attr('aria-labelledby', `${svgTitleID} ${svgDescID}`);
    }
  }

  createStates() {
    this.svg.selectAll('path')
      .data(this.mapData.features)
      .enter()
      .append('path')
      .attr('d', this.path)
      .style('stroke', this.options.statesStrokeColor)
      .style('stroke-width', this.options.statesStrokeWidth)
      .style('fill', this.options.statesColor);
  }

  createMarkers() {
    const self = this;

    this.markers = this.svg.selectAll(this.options.markerHolderClass)
      .data(this.data)
      .enter()
      .append('g')
      .attr('class', this.options.markerHolderClass)
      .attr('transform', (d) => {
        const coordinates = this.projection([d.coordinates[1], d.coordinates[0]]);
        const x = coordinates[0] - this.options.markerWidth / 2;
        const y = coordinates[1] - this.options.markerHeight / 2;

        return `translate(${x}, ${y})`;
      });

    this.markers.append('rect')
      .attr('data-id', (d) => d.id)
      .attr('data-location', (d) => d.location || '')
      .attr('class', this.options.markerClass)
      .attr('role', 'button')
      .attr('aria-', (d) => d.id)
      .attr('width', this.options.markerWidth)
      .attr('height', this.options.markerHeight)
      .style('fill', this.options.markerColor)
      .attr('stroke', this.options.markerStrokeColor)
      .style('stroke-width', this.options.markerStrokeWidth)
      .attr('tabindex', '0')
      .on('mouseover', function() {
        d3.select(this).style('fill', self.options.markerHoverColor);

        self.showPopup(this);
      })
      .on('mouseleave', () => {
        this.onMouseLeave();
      })
      .on('focus', function() {
        d3.select(this).style('fill', self.options.markerHoverColor);
      })
      .on('blur', function() {
        if (!self.activePopup) {
          d3.select(this).style('fill', self.options.markerColor);
        }
      })
      .on('keydown', function(event) {
        if (event.key === 'Enter') {
          if (!self.activePopup) {
            self.isFocused = true;

            self.showPopup(this);
          }
        }
      });
  }

  createPopups() {
    this.createTemplate(this.mapContainer, this.options.popupTemplateAttr, (item) => {
      const popup = this.mapContainer.querySelector(`[id="${item.id}"]`);

      popup.querySelectorAll(this.focusableElements).forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });

      if (focusTrap) {
        popup.trap = focusTrap.createFocusTrap(popup);
      }
    });
  }

  createLocations() {
    if (!this.locationsHolder) return;

    this.createTemplate(this.locationsHolder, this.options.locationTemplateAttr);
  }

  createSelectOptions() {
    if (!this.filterSelect) return;

    for (let i = 0; i < this.data.length; i++) {
      const option = document.createElement('option');

      option.value = this.data[i].location;
      option.textContent = this.data[i].location;
      this.filterSelect.appendChild(option);
    }

    if (this.filterSelect.choices) {
      this.filterSelect.choices.refresh(false, true, true);
    }
  }

  createTemplate(container, templateAttr, callback) {
    const templateID = this.mapContainer.getAttribute(templateAttr);
    const templateElem = document.querySelector(templateID);

    if (!templateElem || !Handlebars) return;

    const source = templateElem.innerHTML;
    const template = Handlebars.compile(source);

    for (let i = 0; i < this.data.length; i++) {
      const templateHTML = template(this.data[i]);

      container.insertAdjacentHTML('beforeend', templateHTML);

      if (callback && typeof callback === 'function') {
        callback(this.data[i]);
      }
    }
  }

  showPopup(elem) {
    this.activeMarker = elem;

    clearTimeout(this.animTimer);

    const id = this.activeMarker.getAttribute('data-id');
    const popup = document.getElementById(`${id}`);

    if (!popup || (this.activePopup && this.activePopup === popup)) return;

    this.activePopup = popup;

    const markerRect = this.activeMarker.getBoundingClientRect();
    const popupRect = this.activePopup.getBoundingClientRect();
    const popupHeight = popupRect.height;
    const popupWidth = popupRect.width;
    const leftPosition = markerRect.left + window.scrollX;
    const topPosition = markerRect.top + window.scrollY;

    this.activePopup.classList.add(this.options.activePopupClass);
    this.activePopup.style.top = topPosition - popupHeight + 'px';
    this.activePopup.style.left = leftPosition + 'px';

    this.setAttributesForPopup(true);

    this.activePopup.querySelectorAll(this.focusableElements).forEach(item => {
      item.removeAttribute('tabindex');
    });

    this.activePopup.addEventListener('mouseover', this.onMouseOver.bind(this));
    this.activePopup.addEventListener('mouseleave', this.onMouseLeave.bind(this));

    if (markerRect.left + window.scrollX + (popupWidth / 2) + 10 > this.getWindowWidth()) {
      this.activePopup.classList.add(this.options.rightSideClass);
    } else {
      this.activePopup.classList.remove(this.options.rightSideClass);
    }

    if (markerRect.left - (popupWidth / 2) - 10 < 0) {
      this.activePopup.classList.add(this.options.leftSideClass);
    } else {
      this.activePopup.classList.remove(this.options.leftSideClass);
    }

    if (this.activePopup.trap && this.isFocused) {
      this.activePopup.trap.activate();
    }
  }

  hidePopup() {
    if (this.activePopup) {
      this.activePopup.classList.remove(this.options.activePopupClass);
      this.activePopup.style.top = '';
      this.activePopup.style.left = '';

      this.setAttributesForPopup(false);

      this.activePopup.removeEventListener('mouseover', this.onMouseOver.bind(this));
      this.activePopup.removeEventListener('mouseleave', this.onMouseLeave.bind(this));

      this.activePopup.querySelectorAll(this.focusableElements).forEach(item => {
        item.setAttribute('tabindex', '-1');
      });

      if (this.activePopup.trap) {
        this.activePopup.trap.deactivate();
      }

      this.activePopup = null;
    }

    if (this.activeMarker) {
      this.activeMarker.style.fill = this.options.markerColor;
      this.activeMarker = null;
      this.isFocused = false;
    }
  }

  onMouseOver() {
    clearTimeout(this.animTimer);
  }

  onMouseLeave() {
    this.animTimer = setTimeout(() => {
      this.hidePopup();
    }, this.options.hideDelay);
  }

  setAttributesForPopup(isVisible) {
    this.activePopup.setAttribute('aria-hidden', !isVisible);

    if (isVisible) {
      this.activePopup.removeAttribute('tabindex');
      this.activePopup.setAttribute('role', 'tooltip');
    } else {
      this.activePopup.setAttribute('tabindex', '-1');
      this.activePopup.removeAttribute('role');
    }
  }

  getWindowWidth() {
    return typeof window.innerWidth === 'number' ? window.innerWidth : document.documentElement.clientWidth;
  }

  makeCallback(callbackName, ...args) {
    if (typeof this.options[callbackName] === 'function') {
      this.options[callbackName].apply(this, args);
    }
  }
}
