/*
* FIxed Header module
*/
export default class FixedHeader {
  constructor(elem, options) {
    this.options = {
      activeClass: 'fixed-header',
      ...options
    };

    this.header = elem;
    this.header.FixedHeader = this;

    this.init();
  }

  init() {
    this.isFixed = false;
    this.offsetTop = 0;

    this.attachEvents();
    this.makeCallback('onInit', this);
  }

  attachEvents() {
    this.resizeHandler();

    this.onResize = this.resizeHandler.bind(this);
    this.onScroll = this.scrollHandler.bind(this);

    window.addEventListener('load', this.onResize);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  }

  resizeHandler() {
    document.body.classList.remove(this.options.activeClass);

    this.isFixed = false;
    this.offsetTop = this.header.getBoundingClientRect().top + window.scrollY;

    this.scrollHandler();
  }

  scrollHandler() {
    if (this.offsetTop < window.scrollY) {
      if (!this.isFixed) {
        this.isFixed = true;

        document.body.classList.add(this.options.activeClass);
      }
    } else {
      if (this.isFixed) {
        this.isFixed = false;

        document.body.classList.remove(this.options.activeClass);
      }
    }
  }

  makeCallback(callbackName, ...args) {
    if (typeof this.options[callbackName] === 'function') {
      this.options[callbackName].apply(this, args);
    }
  }
}
