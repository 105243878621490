/*
* Mobile Menu module
*/
export default class MobileNavigation {
  constructor(holder, options) {
    this.options = {
      listItems: 'li',
      opener: 'a',
      dropdown: '.drop',
      btnBackClass: 'btn-back',
      btnBackPrefix: '< ',
      activeClass: 'active-item',
      activeListClass: 'active-nav',
      hasDropClass: 'has-drop',
      animSpeed: 500,
      ...options
    };

    this.holder = holder;
    this.holder.MobileNavigationAPI = this;
    this.init();
  }

  init() {
    this.findElements();
    this.attachEvents();
    this.makeCallback('onInit', this);
  }

  findElements() {
    this.listItems = this.holder.querySelectorAll(this.options.listItems);
    this.itemLinks = [];
    this.allButtons = [];
    this.levelCounter = 0;
  }

  attachEvents() {
    this.listItems.forEach(item => {
      const opener = item.querySelector(this.options.opener);
      const drop = item.querySelector(this.options.dropdown);
      const btnBack = document.createElement('a');
      const backText = this.options.btnBackPrefix + opener.textContent;

      if (!drop) return;

      item.classList.add(this.options.hasDropClass);
      btnBack.classList.add(this.options.btnBackClass);
      btnBack.href = '#';
      btnBack.textContent = backText;
      drop.prepend(btnBack);

      this.itemLinks.push(opener);
      this.allButtons.push(btnBack);

      opener.data = {
        item,
        btnBack
      };
    });

    this.itemLinks.forEach(currOpener => {
      const btnBack = currOpener.data.btnBack;

      if (btnBack) {
        btnBack.addEventListener('click', (e) => {
          e.preventDefault();
          this.hideDrop(currOpener.data.item);
        });
      }
    });

    this.clickHandler = function(e) {
      e.preventDefault();
      this.showDrop(e.currentTarget.data.item);
    };

    this.itemLinks.forEach(item => {
      item.addEventListener('click', (e) => {
        this.clickHandler(e);
      });
    });
  }

  showDrop(item) {
    this.levelCounter++;

    for (const sibling of item.parentNode.children) {
      if (sibling !== item) {
        sibling.classList.remove(this.options.activeClass);
      }
    }

    item.classList.add(this.options.activeClass);

    if (item.closest(this.options.dropdown)) {
      item.closest(this.options.dropdown).classList.add(this.options.activeListClass);
    } else {
      item.closest('ul').classList.add(this.options.activeListClass);
    }
  }

  hideDrop(item) {
    this.levelCounter--;
    item.closest('.' + this.options.activeListClass).classList.remove(this.options.activeListClass);

    setTimeout(() => {
      item.classList.remove(this.options.activeClass);
    }, this.options.animSpeed);
  }

  hideAllDropdowns() {
    this.levelCounter = 0;

    this.listItems.forEach(item => {
      item.classList.remove(this.options.activeClass);
    });

    this.holder.querySelectorAll(this.options.dropdown).forEach(item => {
      item.classList.remove(this.options.activeListClass);
    });

    this.holder.classList.remove(this.options.activeListClass);
  }

  makeCallback(callbackName, ...args) {
    if (typeof this.options[callbackName] === 'function') {
      this.options[callbackName].apply(this, args);
    }
  }

  destroy() {
    this.itemLinks.forEach(item => {
      item.removeEventListener('click', this.clickHandler);
    });

    this.listItems.forEach(item => {
      item.classList.remove(this.options.activeClass);
    });

    this.holder.querySelectorAll(this.options.dropdown).forEach(item => {
      item.classList.remove(this.options.activeListClass);
    });

    this.holder.classList.remove(this.options.activeListClass);

    this.allButtons.forEach(item => {
      item.remove();
    });

    this.holder.removeAttribute('style');
  }
}
