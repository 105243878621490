import AOS from 'aos';

// Swiper init
export default function initAos() {
  AOS.init({
    duration: 1000,
    offset: 200,
    once: true
  });

  window.addEventListener('refreshAOS', function() {
    AOS.refresh();
  });
}
