import CustomMap from '../plugins/customMapPlugin';
import initLocationsFiltering from '../components/locationsFiltering';

export default function initCustomMap() {
  document.querySelectorAll('.locations').forEach((holder) => {
    new CustomMap(holder, {
      mapContainer: '.map-container',
      locationsHolder: '.accordion-locations',
      mapDataAttr: 'data-json',
      markersDataAttr: 'data-markers',
      popupTemplateAttr: 'data-popup-template',
      popupClass: 'map-popup',
      activePopupClass: 'active-popup',
      markerHolderClass: 'marker-holder',
      markerClass: 'marker',
      markerWidth: 10,
      markerHeight: 10,
      markerColor: '#A9FF73',
      markerHoverColor: '#EC811E',
      markerStrokeWidth: 1,
      markerStrokeColor: '#225182',
      statesColor: '#1C75BC',
      statesStrokeColor: '#fff',
      statesStrokeWidth: 1,
      hideDelay: 100,
      onInit: function() {
        initLocationsFiltering();
      }
    });
  });
}
