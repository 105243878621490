import Swiper, { Navigation, Pagination, A11y, Autoplay } from '../../../node_modules/swiper';

// Swiper init
export default function initSwiper() {
	document.querySelectorAll('.hero-slider').forEach((slider) => {
		const pagination = slider.querySelector('.swiper-pagination');
		const navigationPrev = slider.querySelector('.swiper-button-prev');
		const navigationNext = slider.querySelector('.swiper-button-next');
		const autoplayDelay = 5000;
		let autoplayTimer = null;

		const swiper = new Swiper(slider, {
			modules: [Navigation, Pagination, A11y],
			loop: true,
			a11y: {
				enabled: true
			},
			pagination: {
				el: pagination,
				clickable: true
			},
			navigation: {
				nextEl: navigationNext,
				prevEl: navigationPrev
			},
			on: {
				init: function(swiper) {
					startVideo(swiper);
				},
				slideChange: function(swiper) {
					startVideo(swiper);
				}
			}
		});

		function startVideo(swiper) {
			const currentSlide = swiper.slides[swiper.activeIndex];
			const video = currentSlide.querySelector('[data-video]');
			const videoData = JSON.parse(video.dataset.video);

			clearTimeout(autoplayTimer);

			if (video && videoData.video !== '') {
				const videoAPI = video.BgVideo;

				if (videoAPI) {
					videoAPI.player.restart();
					videoAPI.player.play();
				}

				video.addEventListener('endedVideo', () => {
					swiper.slideNext();
				});
			} else {
				autoplayTimer = setTimeout(() => {
					swiper.slideNext();
				}, autoplayDelay);
			}
		}
	});

	document.querySelectorAll('.slider-holder').forEach((holder) => {
		const slider = holder.querySelector('.services-slider');
		const sliderButtonNext = holder.querySelector('.swiper-button-next');
		const sliderButtonPrev = holder.querySelector('.swiper-button-prev');

		const swiper = new Swiper(slider, {
			modules: [Navigation, A11y],
			slidesPerView: 1,
			spaceBetween: 12,
			loop: true,
			a11y: {
				enabled: true
			},
			navigation: {
				nextEl: sliderButtonNext,
				prevEl: sliderButtonPrev
			},
			breakpoints: {
				768: {
					spaceBetween: 20,
					slidesPerView: 2.5
				},
				992: {
					spaceBetween: 40,
					slidesPerView: 2.5
				},
				1200: {
					spaceBetween: 40,
					slidesPerView: 2.5
				},
				1400: {
					spaceBetween: 68,
					slidesPerView: 3.5
				}
			}
		});
	});

	document.querySelectorAll('.images-slider').forEach((slider) => {
		const navigationPrev = slider.querySelector('.swiper-button-prev');
		const navigationNext = slider.querySelector('.swiper-button-next');

		const swiper = new Swiper(slider, {
			modules: [Navigation, A11y],
			slidesPerView: 1,
			spaceBetween: 0,
			loop: true,
			a11y: {
				enabled: true
			},
			navigation: {
				nextEl: navigationNext,
				prevEl: navigationPrev
			},
			on: {
				slideChangeTransitionStart: function(swiper) {
					const video = swiper.slides[swiper.previousIndex].querySelector('[data-video]');

					if (video) {
						const videoAPI = video.BgVideo;

						if (videoAPI) videoAPI.player.pause();
					}
				}
			}
		});
	});

	document.querySelectorAll('.full-width-slider').forEach((slider) => {
		const navigationPrev = slider.querySelector('.swiper-button-prev');
		const navigationNext = slider.querySelector('.swiper-button-next');
		const slides = slider.querySelectorAll('.swiper-slide');
		let loop = true;

		if (slides.length === 1) {
			loop = false;
		}

		const swiper = new Swiper(slider, {
			modules: [Navigation, A11y],
			slidesPerView: 1,
			spaceBetween: 0,
			loop,
			centeredSlides: true,
			a11y: {
				enabled: true
			},
			navigation: {
				nextEl: navigationNext,
				prevEl: navigationPrev
			},
			breakpoints: {
				768: {
					slidesPerView: 1.74,
					spaceBetween: 0
				}
			},
			on: {
				slideChangeTransitionStart: function(swiper) {
					const video = swiper.slides[swiper.previousIndex].querySelector('[data-video]');

					if (video) {
						const videoAPI = video.BgVideo;

						if (videoAPI) videoAPI.player.pause();
					}
				}
			}
		});
	});

	document.querySelectorAll('.cards-slider').forEach((slider) => {
		const pagination = slider.querySelector('.swiper-pagination');
		const navigationPrev = slider.querySelector('.swiper-button-prev');
		const navigationNext = slider.querySelector('.swiper-button-next');

		const swiper = new Swiper(slider, {
			modules: [Navigation, Pagination, A11y, Autoplay],
			loop: true,
			autoplay: {
				delay: 3000
			},
			a11y: {
				enabled: true
			},
			pagination: {
				el: pagination,
				clickable: true
			},
			navigation: {
				nextEl: navigationNext,
				prevEl: navigationPrev
			}
		});
	});
}
